import React from 'react';

const Rings: React.FC<React.SVGProps<SVGSVGElement>> = ({ ...props }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_47_1039)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.70715 4.04103C8.3785 3.86561 10.0604 4.25593 11.4837 5.14951C12.9069 6.04308 13.9893 7.38827 14.5576 8.9698C15.1259 10.5513 15.1472 12.2778 14.6182 13.8729L14.3034 14.822L12.405 14.1924L12.7199 13.2433C13.108 12.073 13.0923 10.8064 12.6754 9.64611C12.2585 8.48582 11.4644 7.49892 10.4202 6.84334C9.37603 6.18776 8.14211 5.90141 6.91591 6.0301C5.68972 6.1588 4.54214 6.6951 3.65682 7.55318C2.7715 8.41127 2.19961 9.54153 2.03268 10.7631C1.86574 11.9847 2.11341 13.2269 2.73605 14.2911C3.35868 15.3553 4.3203 16.1798 5.467 16.6328C6.61371 17.0857 7.87921 17.1409 9.06101 16.7895L10.0195 16.5045L10.5895 18.4216L9.63101 18.7066C8.02017 19.1855 6.29523 19.1103 4.73222 18.4929C3.16922 17.8755 1.8585 16.7516 1.00982 15.3011C0.161136 13.8506 -0.176447 12.1574 0.0510929 10.4923C0.278632 8.82726 1.05814 7.28666 2.26487 6.11705C3.4716 4.94744 5.0358 4.21644 6.70715 4.04103Z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.3691 4.32954C15.9799 3.85059 17.7048 3.92584 19.2678 4.54325C20.8309 5.16066 22.1416 6.28453 22.9902 7.73502C23.8389 9.18552 24.1765 10.8788 23.949 12.5438C23.7214 14.2089 22.9419 15.7495 21.7352 16.9191C20.5285 18.0887 18.9643 18.8197 17.2929 18.9951C15.6216 19.1705 13.9397 18.7802 12.5164 17.8866C11.0931 16.9931 10.0108 15.6479 9.44247 14.0663C8.87419 12.4848 8.85287 10.7584 9.3819 9.16327L9.69671 8.21411L11.595 8.84372L11.2802 9.79288C10.8921 10.9631 10.9077 12.2297 11.3247 13.39C11.7416 14.5503 12.5357 15.5372 13.5799 16.1928C14.624 16.8484 15.858 17.1347 17.0842 17.006C18.3103 16.8773 19.4579 16.341 20.3432 15.483C21.2286 14.6249 21.8005 13.4946 21.9674 12.273C22.1343 11.0515 21.8867 9.8092 21.264 8.74504C20.6414 7.68088 19.6798 6.85635 18.5331 6.40339C17.3864 5.95042 16.1209 5.89522 14.9391 6.2466L13.9805 6.5316L13.4105 4.61454L14.3691 4.32954Z"
        />
      </g>
      <defs>
        <clipPath id="clip0_47_1039">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Rings;
